/* Styles généraux */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #F3E5F5; /* Fond général plus clair */
}

.App {
    max-width: 1200px;
    margin: auto;
    overflow: hidden;
}

/* Header et navigation */
header {
  background-color: #9C27B0; /* Couleur principale pourpre */
  color: #ffffff;
  padding: 00px 0;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
    margin: 0;
  position: fixed;
  width: 1200px;
  top: 0;
  z-index: 1000;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  max-width: 1200px;
  margin: auto;
}

.logo {
  font-weight: bold;
  font-size: 24px;
  cursor: pointer;
}

.nav-links {
  display: flex;
  list-style: none;
  padding: 0;
}

.nav-links li {
  padding: 0 15px;
}

.nav-links a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #FFC107; /* Ajoute un effet au survol */
}

@media (max-width: 768px) {
  .nav-links {
    display: none; /* Simplifie la navigation sur les appareils mobiles */
  }

  /* Vous pouvez ajouter ici un menu burger pour mobile */
}

/* Hero Section */
.hero-section {
    color: #ffffff;
    background-color: #7B1FA2; /* Pourpre foncé */
    padding: 80px 20px;
    text-align: center;
}

.hero-section h1 {
    margin-bottom: 20px;
    font-size: 2.5rem;
}

.hero-section p {
    margin-bottom: 20px;
    font-size: 1.2rem;
}

.hero-section button {
    background-color: #FFC107; /* Jaune */
    color: #6A1B9A; /* Pourpre */
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 5px;
}

.hero-section button:hover {
    background-color: #FFD54F;
}

/* Sections de contenu */
.section {
    padding: 60px 20px;
    text-align: left;
}

.section h2 {
    color: #9C27B0; /* Pourpre */
    margin-bottom: 20px;
}

.section p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 20px;
}

/* Effets supplémentaires pour l'élégance */
.section:nth-of-type(odd) {
    background-color: #EDE7F6; /* Pourpre très clair */
}

.section:nth-of-type(even) {
    background-color: #F3E5F5; /* Pourpre encore plus clair */
}

.section:last-child {
    border-bottom: 5px solid #9C27B0; /* Bordure pourpre pour le dernier élément */
}

.section img {
    max-width: 100%;
    aspect-ratio: 1/1;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.section-content {
    text-align: justify;
    text-justify: inter-word;
}


.flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.flex-item-text,
.flex-item-image {
    flex: 1;
}

@media (max-width: 768px) {
    .flex-container {
        flex-direction: column;
    }
}
